import { toast } from "react-toastify";
import { Box, CardContent, CardHeader, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Button, FormLabel, TextareaAutosize, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import FieldErrorMessage from "../../components/FieldErrorMessage";
import SaveButton from "../../components/SaveButton";
import CardLayout from "../../layouts/CardLayout";
import errorHandler from "../../utils/errorHandler";
import CoursesManagementList from "../../services/coursesManagementSerives";
import taskAndQuestionnair from "../../services/taskQuestionniarService";
import apiUsers from "../../services/usersService";
import { CoursesManagementSchema } from "../../utils/Schema";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from "@mui/x-date-pickers";
import Swal from "sweetalert2";
import { Loader } from "../../components/CustomLoader";

function EditAddCoursesManagement() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dropDownLanguage, setDropDownLanguage] = useState([]);
  const [dropDownInstitution, getDropdownInstitution] = useState([]);
  const [dropDownTaskList, getDropdownTaskList] = useState([]);
  const [droupDownTeacherList, getDroupDownTeacherList] = useState([]);
  const [removedTaskIds, setRemovedTaskIds] = useState([]);
  const [selectedInstitutionType, setSelectedInstitutionType] = useState("");
  const [selectedInstitutionDefault, setSelectedInstitutionDeault] = useState("");
  const [fatchAlldataForDefault, setFatchAlldataForDefault] = useState([]);
  const [dependentCourseEntityData, setDependentCourseEntity] = useState([]);

  const fetchTaskData = useCallback(async () => {
    if (id) {
      try {
        const response = await taskAndQuestionnair.getById({ id });
        const resData = response?.data?.data;
        resetForm({
          values: {
            id: resData?._id,
            title: resData?.title || "",
            description: resData?.description || "",
            languageId: resData?.languageId || "",
            status: resData?.status || "",
            slug: "Task"
          },
        });

      } catch (error) {
        errorHandler(error);
      } finally {
        // setLoading(false);
      }
    }
    
  }, [id]);

  const fetchDropDownLangauge = async () => {
    setLoading(true); // Show loader before making API call
    try {
      let res = await taskAndQuestionnair.getDropdownLanguages({ slug: "Language" });
      setDropDownLanguage(res?.data?.data)
    } catch (error) {
      errorHandler(error);
    }

    try {
      let res = await taskAndQuestionnair.getDropdownLanguages({ slug: "InstitutionType" });
      getDropdownInstitution(res?.data?.data)
    } catch (error) {
      errorHandler(error);
    }
    
    try {
      
      let res = await apiUsers.getAll({ type: "teacher" });
      if (res.status === 200) {
        setTimeout(() => {
          getDroupDownTeacherList(res?.data?.data)
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchDropDownLangauge();
  }, []);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const formik = useFormik({
    initialValues: {
      courseName: "",
      description: "",
      isDeleted: false,
      semester: {_id: "" } ,
      status: "",
      year: "",
      // courseLevel: "",
      courseLevel: { id: "", title: "" },
      otherCourseLevelDescription: "",
      otherInstitutionDescription: "",
      creatorData: {
        _id:"",
        name: "",
        email: "",
      },
      institution: {_id: "" , title:"" } ,
      institutionType: {title: "" },
      language: "" ,
      isOtherInstitution: false,
      isOtherCourseLevel: false,
      tasks: [
        {
          task: "",
          taskCutOff: "",
          startDate: "",
          endDate: "",
          dueDate: "",
        },
      ],
    },

    validationSchema: CoursesManagementSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let payload = {}
          if (id) {                       
            payload = { ...values,
              year: values?.year.toString() ,
              institution : values?.institution?._id,
              institutionType :values?.institutionType?.title,
              language : values?.language?._id,
              year: values?.year.toString(),  
              courseLevel: values?.courseLevel._id,
              semester: values?.semester?._id,
              otherCourseLevelDescription : values?.courseLevel._id === "other" ? values?.otherCourseLevelDescription : "",
              otherInstitutionDescription : values?.institution._id === "other" ? values?.otherInstitutionDescription : "",

            };  
          }else {
            payload = {
              ...values,
              institution : values?.institution?._id,
              institutionType :values?.institutionType?.title,
              language : values?.language?._id,
              year: values?.year.toString(),
              courseLevel: values?.courseLevel._id,
              semester: values?.semester?._id,
              otherCourseLevelDescription : values?.courseLevel._id === "other" ? values?.otherCourseLevelDescription : "",
              otherInstitutionDescription : values?.institution._id === "other" ? values?.otherInstitutionDescription: "",
            };
          }

          if (values?.institutionType?.title === 'k12') {
            delete payload.institution;
            delete payload.semester;
          } 

          if (id) {
              // delete payload.creatorData;
              payload.creatorId = values?.creatorData?._id;
          }
           delete payload.creatorData;

          if (!payload.creatorId) {
              payload.creatorId = values?.creatorData?._id;
          }else{
              //delete payload.creatorId
          }

          if(values?.courseLevel._id === "other"){
            delete payload.creatorData;
          }
          const res = id
          ? await CoursesManagementList.edit(payload)
          : await CoursesManagementList.add(payload);

        toast.success(res?.data?.message);
        navigate("/courses-management");
      } catch (error) {
        errorHandler(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, errors, touched,setFieldError, handleSubmit, getFieldProps, setFieldValue, resetForm ,handleBlur ,setFieldTouched} = formik;

  const handleLanguageChange = async (event) => {
    const selectedLanguageId = event.target.value;
    setFieldValue("language._id", selectedLanguageId);
    try {
      // setLoading(true);
      const response = await CoursesManagementList.taskget({ 
        languageId: selectedLanguageId,
        slug: "Task"
      });
      getDropdownTaskList(response?.data?.data)
    } catch (error) {
      errorHandler(error);
    } finally {
      // setLoading(false);
    }
  };
  
          
  const fetchTaskData1 = useCallback(async () => {
    if (values.language) {
      // setLoading(true);
      try {
        const response = await CoursesManagementList.taskget({ 
          languageId: values?.language?._id,
          slug: "Task"
        });
        getDropdownTaskList(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        // setLoading(false);
      }
    }
  }, [values.language]);


  const handleRemoveTask = (index, taskId) => {
    const newTasks = values.tasks.filter((_, i) => i !== index);
    setFieldValue("tasks", newTasks); 
    if (taskId) {
      setRemovedTaskIds((prev) => [...prev, taskId]);
    }
  };
  

  useEffect(() => {
    values?.tasks?.forEach((task, index) => {
      const isValidTask = dropDownTaskList.some(item => item._id === task.task);
      if (!isValidTask) {
        setFieldValue(`tasks[${index}].task`, null);
      }
    });
  }, [dropDownTaskList]);

  useEffect(() => {
    if (id) {
      const getInstitutionById = async () => {
        try {
          const response = await CoursesManagementList.getById({ id });
          const resData = response?.data?.data;
          setSelectedInstitutionDeault(resData?.institutionType?.title)
          setFatchAlldataForDefault(resData)
          setSelectedInstitutionType(resData?.institutionType?.title)
          resetForm({
            values: {
              id: id,
              courseName: resData?.courseName || "",
              description: resData?.description || "",
              isDeleted: resData?.isDeleted || false,
              semester: {
                _id: resData?.semester?._id || "",
              },
              creatorId:  resData?.creatorId || "",
              status: resData?.status || "",
              year: resData?.year || "",
              courseLevel: {
                _id: resData?.isOtherCourseLevel ? "other" : resData?.courseLevel?._id,
                title: resData?.isOtherCourseLevel ? "other" : resData?.courseLevel?.title,
              },
              creatorData: {
                _id:    resData?.creatorData?._id || "",
                name:   resData?.creatorData?.name || "",
                email:  resData?.creatorData?.email || "",
              },
              institution: {
                _id:  resData?.isOtherInstitution ? "other" : resData?.institution?._id ,
                title:  resData?.isOtherInstitution ? "other" : resData?.institution?.title 
              },
              institutionType: {
                title: resData?.institutionType?.title || "",
              },
              language: {
                _id: resData?.language?._id || "",
              },
              otherCourseLevelDescription: resData?.otherCourseLevelDescription || "",
              otherInstitutionDescription: resData?.otherInstitutionDescription || "",
              isOtherInstitution: resData?.isOtherInstitution ? resData?.isOtherInstitution:false,
              isOtherCourseLevel: resData?.isOtherCourseLevel ? resData?.isOtherCourseLevel:false,

              status: resData?.status || "false",
              tasks: resData?.tasks?.map((task) => ({
                task: task?.task || "",
                taskCutOff: task?.taskCutOff || "",
                startDate: task?.startDate || "",
                endDate: task?.endDate || "",
                dueDate: task?.dueDate || "",
              })) || [
                {
                  task: "",
                  taskCutOff: "",
                  startDate: "",
                  endDate: "",
                  dueDate: "",
                },
              ],
            },
          });
        } catch (error) {
          errorHandler(error);
        }
      };
      getInstitutionById();
    }
  }, [id, resetForm]);

  useEffect(() => {
    if (values?.language) {
      fetchTaskData1();
    }
  }, [fetchTaskData1]);

  const handleInstitutionTypeChange = (e) => {
    const selectedType = e.target.value;   
    setSelectedInstitutionType(selectedType);
    setFieldValue("institutionType.title", selectedType);
    setFieldTouched("institutionType", true);

    if(selectedInstitutionDefault=== selectedType){
      setFieldValue("courseLevel._id", fatchAlldataForDefault?.courseLevel?._id );
    }else{
      setFieldValue("courseLevel._id", "");
    }
  };


  useEffect(() => {
    const fetchCourseCreateData = async () => {
      const payload = {
        slug: selectedInstitutionType,
      };
  
      try {
        let res = await CoursesManagementList.getAllCourseEntityCreateCourse(payload);
        setDependentCourseEntity(res?.data?.data);
      } catch (error) {
        errorHandler(error);
        console.error("Error fetching course data:", error);
      }
    };
    if (selectedInstitutionType && selectedInstitutionType.trim() !== '') {
      fetchCourseCreateData();
    }
  }, [selectedInstitutionType]);
  
  return (
    <Box>
     
      <Helmet>
        <title>{id ? "Edit" : "Add"} Courses</title>
      </Helmet>
        <CardLayout>
          <CardHeader title={`${id ? "Edit" : "Add"} Courses`} />
          <CardContent>
            <FormikProvider value={formik}>
              {loading?  <Loader /> :(
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>

                    <Grid item xs={6}>
                    <FormControl fullWidth error={Boolean(touched?.language && errors?.language)}>
                        <InputLabel id="language-select-label">Language</InputLabel>
                        <Select
                          labelId="language-select-label"
                          id="language-select"
                          label="Language"
                          value={values?.language?._id || ""} 
                          onChange={handleLanguageChange}
                        >
                          {dropDownLanguage?.map((item) => (
                            <MenuItem key={item?._id} value={item?._id}>
                              {item?.title}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{touched.language && errors.language}</FormHelperText>
                      </FormControl>                  
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth error={Boolean(touched.institutionType?.title && errors.institutionType?.title)}>
                        <InputLabel id="institution-type">Institution Type</InputLabel>
                        <Select
                          labelId="institution-type"
                          id="institution-type"
                          label="Institution Type"
                          value={values?.institutionType?.title || ""}
                          onChange={handleInstitutionTypeChange}
                          onBlur={handleBlur}
                        >
                          {dropDownInstitution?.map((item) => (
                            <MenuItem key={item?._id} value={item?.title}>
                              {item?.title}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{touched.institutionType?.title && errors.institutionType?.title}</FormHelperText>
                      </FormControl>
                    </Grid>

                    {selectedInstitutionType === "k12" && (
                      <>
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.year && errors.year)}
                          >
                            <InputLabel id="year-label">Year</InputLabel>
                            <Select
                              labelId="year-label"
                              id="year-label"
                              label="Year"
                              {...getFieldProps("year")}
                            >
                              {dependentCourseEntityData?.Year?.map((item) => (
                                <MenuItem key={item?.value} value={item?.value}>
                                  {item?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {touched.year && errors.year}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.courseLevel?._id && errors.courseLevel?._id)}>
                            <InputLabel id="courselevel-label">Course Level</InputLabel>
                            <Select
                              labelId="courselevel-label"
                              id="courselevel-label"
                              label="Course Level"value={values?.courseLevel?._id || ""}
                              {...getFieldProps("courseLevel._id")}
                            >
                              {dependentCourseEntityData?.Courselevel?.map((item) => (
                                <MenuItem key={item?.value} value={item?.value}>
                                  {item?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {touched.courseLevel?._id && errors.courseLevel?._id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </>
                    )}

                    {selectedInstitutionType === "postsecondary" && (
                      <>
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.year && errors.year)}
                          >
                            <InputLabel id="year-label">Year</InputLabel>
                            <Select
                              labelId="year-label"
                              id="year-label"
                              label="Year"
                              {...getFieldProps("year")}
                            >
                              {dependentCourseEntityData?.Year?.map((item) => (
                                <MenuItem key={item?.value} value={item?.value}>
                                  {item?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {touched.year && errors.year}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.courseLevel?._id && errors.courseLevel?._id)}
                          >
                            <InputLabel id="courselevel-label">Course Level</InputLabel>
                            <Select
                              labelId="courselevel-label"
                              id="courselevel-label"
                              label="Course Level"
                              value={formik.values.courseLevel._id || ""} 
                              onChange={(e) => {
                                const selectedId = e.target.value;

                                const selectedTitle = dependentCourseEntityData?.Courselevel?.find(option => option.id === selectedId)?.title || "";
                                formik.setFieldValue("courseLevel._id", selectedId);
                                formik.setFieldValue("courseLevel.title", selectedTitle);
                          
                                if (selectedId === 'other') {
                                  setFieldValue("isOtherCourseLevel", true);
                                }else{
                                  formik.setFieldValue("otherCourseLevelDescription", "");
                                  setFieldValue("isOtherCourseLevel", false);
                                }                             
                              }}
                            >

                              {dependentCourseEntityData?.Courselevel?.map((item) => (
                                <MenuItem key={item?.value} value={item?.value}>
                                  {item?.label}
                                </MenuItem>
                              ))}
                              <MenuItem value="other" label="other">Other</MenuItem>
                            </Select>
                            <FormHelperText>{touched.courseLevel?._id && errors.courseLevel?._id}</FormHelperText>
                          </FormControl>
                        </Grid>
                        
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched?.semester && errors?.semester)}
                          >
                            <InputLabel id="semester-label">Semester</InputLabel>
                            <Select
                              labelId="semester-label"
                              id="semester-label"
                              label="Semester"
                              {...getFieldProps("semester._id")}
                            >
                              {dependentCourseEntityData?.Semester?.map((item) => (
                                <MenuItem key={item?.value} value={item?.value}>
                                  {item?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {touched?.semester && errors?.semester}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      

                          <Grid item xs={6}>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                touched.institution && errors.institution
                              )}
                            >
                              <InputLabel id="institution-label">Institution</InputLabel>
                              <Select
                                labelId="institution-label"
                                id="institution"
                                label="Institution"
                                // value={values?.institution?._id}
                                value={formik.values.institution._id || ""}
                                // onChange={(e) => {
                                //   const institution = e.target.value;
                                //   setFieldValue("institution._id", institution);
                                //   if (institution !== "other") {
                                //     setFieldValue("otherInstitutionDescription", ""); // Reset the other institution description if not "other"
                                //   }
                                // }}
                                onChange={(e) => {
                                  const institution = e.target.value;
  
                                  const selectedTitle = dependentCourseEntityData?.Courselevel?.find(option => option.id === institution)?.title || "";
                                  formik.setFieldValue("institution._id", institution);
                                  formik.setFieldValue("institution.title", selectedTitle);
                            
                                  if (institution === 'other') {
                                    setFieldValue("isOtherInstitution", true);
                                  }else{
                                    setFieldValue("isOtherInstitution", false);
                                    formik.setFieldValue("otherInstitutionDescription", "");
                               
                                  }                             
                                }}
                              >

                                {dependentCourseEntityData?.Institution?.map((item) => (
                                  <MenuItem key={item?.value} value={item?.value}>
                                    {item?.label}
                                  </MenuItem>
                                ))}
                                <MenuItem value="other" label="other">Other</MenuItem>
                              </Select>
                              <FormHelperText>{touched.institution && errors.institution}</FormHelperText>
                            </FormControl>
                          </Grid>

                        {values.isOtherCourseLevel && (
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="otherCourseLevelDescription"
                              label="Other Course Level Description"
                              {...getFieldProps("otherCourseLevelDescription")}
                              error={Boolean(touched.otherCourseLevelDescription && errors.otherCourseLevelDescription)}
                              helperText={touched.otherCourseLevelDescription && errors.otherCourseLevelDescription}
                            />
                          </Grid>
                        )}

                        {values.isOtherInstitution && (
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="otherInstitutionDescription"
                              label="Other Institution Description"
                              {...getFieldProps("otherInstitutionDescription")}
                              error={Boolean(touched.otherInstitutionDescription && errors.otherInstitutionDescription)}
                              helperText={touched.otherInstitutionDescription && errors.otherInstitutionDescription}
                            />
                          </Grid>
                        )}
                        </>
                    )}

                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        id="courseName"
                        label="Course Name"
                        {...getFieldProps("courseName")}
                        error={Boolean(touched.courseName && errors.courseName)}
                        helperText={touched.courseName && errors.courseName}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        multiline
                        rows={10}
                        maxRows={10}
                        fullWidth
                        label="Description"
                        {...getFieldProps("description")}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Grid>

                    <CardHeader title={`Choose Tasks`} />
                    <Grid item xs={12}>
                      {errors?.tasks && typeof errors?.tasks === 'string' && (
                        <Typography color="error">{errors?.tasks}</Typography>
                      )}
                      {/* <FormLabel>Tasks</FormLabel> */}
                        {
                          values?.tasks?.length === 0 && (
                            <>
                              <Button
                              type="button"
                              variant="contained"
                              onClick={() =>
                                  setFieldValue("tasks", [
                                  ...values.tasks,
                                  {
                                      task: "",
                                      taskCutOff: "",
                                      startDate: "",
                                      endDate: "",
                                      dueDate: "",
                                  },
                                  ])
                              }
                              >
                              Add Task
                              </Button>
                            </>
                          )
                        }

                        {values?.tasks?.map((task, index) => (
                          <div key={index}>
                            {index >=1 ?  <br/>:null}
                              <FormLabel>{`Task ${index + 1}`}</FormLabel>
                              <br /><br />
                              <Grid container spacing={2}>

                              <Grid item xs={6}>
                                <FormControl fullWidth error={Boolean(touched.tasks?.[index]?.task && errors.tasks?.[index]?.task)}>
                                  <InputLabel id={`task-${index}-select-label`}>{`Task ${index + 1} Name`}</InputLabel>
                                  <Select
                                    id={`task-${index}-select`}
                                    label={`Task ${index + 1} Name`}
                                    name={`tasks[${index}].task`}
                                    value={task?.task || ''}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const isDuplicate = values.tasks.some((t, i) => t.task === value && i !== index);
                                      if (isDuplicate) {
                                        Swal.fire(`Task Allready Select, Please Select unique`);
                                        return;
                                      }
                                      setFieldValue(`tasks[${index}].task`, value);
                                      const selectedTaskId = value;
                                      setFieldValue(`tasks[${index}].task`, selectedTaskId);
                                    }}                                
                                    onBlur={handleBlur}
                                  >
                                    {dropDownTaskList?.map((item) => (
                                      <MenuItem key={item?._id} value={item?._id}>
                                        {item?.title}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <FormHelperText  className='error-custom'>{touched.tasks?.[index]?.task && errors.tasks?.[index]?.task}</FormHelperText>
                              </Grid>

                              <Grid item xs={6}>
                                <FormControl fullWidth error={Boolean(touched.tasks?.[index]?.taskCutOff && errors.tasks?.[index]?.taskCutOff)}>
                                <InputLabel id={`taskCutOff-${index}-select-label`}>{`Task ${index + 1} Cut Off`}</InputLabel>
                                  <Select
                                    id={`taskCutOff-${index}-select`}
                                    label={`Task ${index + 1} Cut Off`}
                                    name={`tasks[${index}].taskCutOff`}
                                    value={task.taskCutOff}
                                    onChange={e => setFieldValue(`tasks[${index}].taskCutOff`, e.target.value)} //
                                    onBlur={handleBlur}
                                  >
                                    <MenuItem value={"10"}>10 %</MenuItem>
                                    <MenuItem value={"20"}>20 %</MenuItem>
                                    <MenuItem value={"30"}>30 %</MenuItem>
                                    <MenuItem value={"40"}>40 %</MenuItem>
                                    <MenuItem value={"50"}>50 %</MenuItem>
                                    <MenuItem value={"60"}>60 %</MenuItem>
                                    <MenuItem value={"70"}>70 %</MenuItem>
                                    <MenuItem value={"80"}>80 %</MenuItem>
                                    <MenuItem value={"90"}>90 %</MenuItem>
                                    <MenuItem value={"95"}>95 %</MenuItem>
                                    <MenuItem value={"100"}>100 %</MenuItem>
                                  </Select>

                                </FormControl>
                                <FormHelperText  className='error-custom'>{touched.tasks?.[index]?.taskCutOff && errors.tasks?.[index]?.taskCutOff}</FormHelperText>
                              </Grid>

                              <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <MobileDatePicker
                                    label={`Task ${index + 1} Start Date`}
                                    name={`tasks[${index}].startDate`}
                                    value={task?.startDate ? new Date(task.startDate) : null}
                                    className='datePicker-custom'
                                    minDate={new Date()}
                                    onBlur={handleBlur}
                                    onChange={(newValue) => {
                                      if (newValue) {
                                        const newTasks = [...values.tasks];
                                        newTasks[index].startDate = newValue;
                                        setFieldValue("tasks", newTasks);
                                      }
                                    }}
                                  />
                                </LocalizationProvider>
                                {errors.tasks?.[index]?.startDate && touched.tasks?.[index]?.startDate && (
                                  <FormHelperText>{touched.tasks?.[index]?.startDate && errors.tasks?.[index]?.startDate}</FormHelperText>
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <MobileDatePicker
                                    label={`Task ${index + 1} End Date`}
                                    name={`tasks[${index}].endDate`}
                                    value={task?.endDate ? new Date(task.endDate) : null}
                                    className='datePicker-custom'
                                    onChange={(newValue) => {
                                      if (newValue) {
                                        const newTasks = [...values.tasks];
                                        const endDateWithTime = new Date(newValue);
                                        endDateWithTime.setHours(23, 59, 59);
                                        newTasks[index].endDate = endDateWithTime;
                                        setFieldValue("tasks", newTasks);
                                      }
                                    }}
                                    minDate={new Date()}
                                    
                                  />
                                </LocalizationProvider>
                                {errors.tasks?.[index]?.endDate && touched.tasks?.[index]?.endDate && (
                                  <FormHelperText>{touched.tasks?.[index]?.endDate && errors.tasks?.[index]?.endDate}</FormHelperText>
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <MobileDatePicker
                                    label={`Task ${index + 1} Due Date`}
                                    value={task?.dueDate ? new Date(task.dueDate) : null}
                                    className='datePicker-custom'
                                    onChange={(newValue) => {
                                      if (newValue) {
                                        const newTasks = [...values.tasks];
                                        const dueDateWithTime = new Date(newValue);
                                        dueDateWithTime.setHours(23, 59, 59);
                                        newTasks[index].dueDate = dueDateWithTime; 
                                        setFieldValue("tasks", newTasks);
                                      }
                                    }}
                                    minDate={new Date()}
                                  />
                                </LocalizationProvider>
                                {errors.tasks?.[index]?.dueDate && touched.tasks?.[index]?.dueDate && (
                                  <FormHelperText>{touched.tasks?.[index]?.dueDate && errors.tasks?.[index]?.dueDate}</FormHelperText>
                                )}
                              </Grid>


                            <Grid item xs={12}>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    className='button-outlined'
                                    onClick={() => {
                                      const newTasks = values?.tasks?.filter((_, i) => i !== index);
                                      setFieldValue("tasks", newTasks);
                                    }}
                                >
                                Remove
                                </Button>

                              
                                {index === values?.tasks?.length - 1 && (
                                <Button
                                  type="button"
                                  variant="contained"
                                  sx={{ ml: 2, mr: 2 }}
                                  onClick={() =>
                                  setFieldValue("tasks", [
                                    ...values.tasks,
                                      {
                                      task: "",
                                      taskCutOff: "",
                                      startDate: "",
                                      endDate: "",
                                      dueDate: "",
                                      },
                                    ])
                                  }
                                >
                                    Add Task
                                </Button>
                                )}
                            </Grid>
                          </Grid>
                          </div>
                        ))}
                    </Grid>

                    <CardHeader title={`Teacher Data `} />
                    <Grid item xs={12}></Grid>
      
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth error={Boolean(touched.creatorData?._id && errors.creatorData?._id)}>
                        <InputLabel id="CreatorEmail-type">Teacher Email</InputLabel>
                        <Select
                          labelId="CreatorEmail-type"
                          id="CreatorEmail"
                          label="Teacher Email"
                          value={values?.creatorData?._id} 
                          onChange={(e) => {
                            const selectedTeacherId = e.target.value;
                            const selectedTeacher = droupDownTeacherList.find(teacher => teacher._id === selectedTeacherId);
                            
                            setFieldValue("creatorData._id", selectedTeacherId);
                            setFieldValue("creatorData.name", selectedTeacher?.name || "");
                          }}
                        >
                          {droupDownTeacherList?.map((item) => (
                            <MenuItem key={item?._id} value={item?._id}>
                              {item?.email}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{touched.creatorData?._id && errors.creatorData?._id}</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        id="CreatorName"
                        label="Teacher Name"
                        disabled
                        {...getFieldProps("creatorData.name")}
                        value={values?.creatorData?.name || ""}
                        error={Boolean(touched.creatorData?.name && errors.creatorData?.name)}
                        helperText={touched.creatorData?.name && errors.creatorData?.name}
                      />
                    </Grid>
                    
                    <Grid item xs={12}>
                      <FormControl fullWidth error={Boolean(touched?.status && errors?.status)}>
                        <InputLabel id="status-select-label">Status</InputLabel>
                        <Select
                          labelId="status-select-label"
                          id="status-select"
                          value={values.status}
                          label="Status"
                          onChange={(e) => setFieldValue("status", e.target.value)}
                        >
                          <MenuItem value={true}>Active</MenuItem>
                          <MenuItem value={false}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                      <FieldErrorMessage formik={formik} name="status" />
                    </Grid>                

                  </Grid>
                  <SaveButton loading={loading} id={id} />
                </Form>
              )}
            </FormikProvider>
          </CardContent>
        </CardLayout>

    </Box>
  );
}

export default EditAddCoursesManagement;