import { Box, Button, Card, CardContent, CardHeader, IconButton, Tooltip, TextField} from "@mui/material";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import errorHandler from "../../utils/errorHandler";
import { getSrno } from "../../utils/helpers";
import SearchInput from "../../components/SearchInput";
import { toast } from "react-toastify";
import { EditOutlined } from "@mui/icons-material";
import TeacherManagementService from "../../services/TeacherManagementService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { fDate } from "../../utils/formatTime";

export default function TeacherManagement() {
  const navigate = useNavigate();

  const [paginData, setPaginData] = useState({
    totalCount: 0,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    per_page: 10,
    current_page: 1,
    search: "",
    sortObject: { createdAt: -1 },
    type: "teacher",
    start_date: null,
    end_date: null,  
  });

  const fetchInstitutionList = async () => {
    try {
      setLoading(true);
      let res = await TeacherManagementService.getAll({
        ...payload,
        start_date: payload.start_date,
        end_date: payload.end_date,
      });
      setPaginData({
        data: res?.data?.data,
        totalCount: res?.data?.totalCount,
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInstitutionList();
  }, [payload]);

  const deleteData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#44070B",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await TeacherManagementService.delete({ id });
          if (res?.status === 200) {
            toast.success(res?.data?.message, { toastId: "success" });
            setPaginData((prev) => ({
              ...prev,
              data: prev.data.filter((item) => item._id !== id),
              totalCount: prev.totalCount - 1,
            }));
          } else {
            toast.error(res?.data?.message, { toastId: "error" });
          }
        } catch (error) {
          console.error("Error deleting...", error);
          errorHandler(error);
        }
      }
    });
  };

  const columns = [
    {
      name: "Sr. No.",
      cell: (row, index) =>
        getSrno(payload.current_page, payload.per_page, index),
      grow: 0.6,
    },
    {
      name: "Name",
      sortField: "name",
      sortable: true,
      selector: (row) => row?.name,
    },
    {
      name: "Email ID",
      sortField: "emailid",
      sortable: true,
      selector: (row) => row?.email,
    },
    {
      name: "Date",
      sortField: "createdAt",
      sortable: true,
      selector: (row) => fDate(row?.createdAt),
    },
    {
      name: "Status",
      sortField: "isBlocked",
      sortable: true,
      selector: (row) => (row?.isBlocked ? "Inactive" : "Active"),
    },
    {
      name: "Action",
      right: true,
      cell: (row) => (
        <>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/teacher-management/edit/${row._id}`);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip title="View" placement="top" leaveDelay={50}>
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/teacher-management/view/${row._id}`);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>

          {/* Uncomment the delete button if you want to use it */}
          {/* <Tooltip title="Delete" placement="right">
            <IconButton color="primary" onClick={() => deleteData(row?._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip> */}
        </>
      ),
    },
  ];

  const handleSort = (column, sortDirection) => {
    const srt = {
      [column.sortField]: sortDirection === "asc" ? -1 : 1,
    };
    setPayload((prev) => ({
      ...prev,
      sortObject: srt,
    }));
  };

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "h4" }}
        title="Teacher Management"
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <SearchInput data={payload} setSearch={setPayload} />
        <Button
          size="large"
          type="submit"
          variant="contained"
          sx={{ ml: 3, mr: 3 }}
          onClick={() => navigate("/teacher-management/add")}
        >
          Add
        </Button>
      </Box>
      <CardContent>

        {/* Date Range Filter */}
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={payload.start_date ? new Date(payload.start_date).toISOString().split('T')[0] : ""}
          onChange={(e) =>
            setPayload((prev) => ({ ...prev, start_date: new Date(e.target.value) }))
          }
          sx={{ marginRight: 2 }}
        />

        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={payload.end_date ? new Date(payload.end_date).toISOString().split('T')[0] : ""}
          onChange={(e) => {
            const selectedEndDate = new Date(e.target.value);
            // Set time to 23:59:59 for the end date
            selectedEndDate.setHours(23, 59, 59);
            setPayload((prev) => ({ ...prev, end_date: selectedEndDate }));
          }}
        />

        <Button
          size="large"
          type="submit"
          variant="outlined"
          sx={{ ml: 3, mr: 3 }}
          onClick={() =>
            setPayload((prev) => ({ ...prev, start_date: null, end_date: null }))
          }
        >
          Clear
        </Button>

        <DataTable
          customStyles={{
            headCells: {
              style: {
                fontSize: "15px",
                fontWeight: "bold",
              },
            },
          }}
          columns={columns}
          data={paginData?.data}
          pagination
          paginationServer
          paginationTotalRows={paginData?.totalCount}
          onChangePage={(page) =>
            setPayload((prev) => ({ ...prev, current_page: page }))
          }
          paginationPerPage={payload.per_page}
          paginationRowsPerPageOptions={[5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
            setPayload((prev) => ({
              ...prev,
              per_page: currentRowsPerPage,
              current_page: currentPage,
            }));
          }}
          progressPending={loading}
          onSort={handleSort}
          progressComponent={<CustomLoader />}
        />
        
      </CardContent>
    </Card>
  );
}