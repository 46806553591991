// import AxiosInterceptor from "../utils/AxiosInterceptor";

// const TeacherManagementService = {
//   edit: (value) => AxiosInterceptor().post("/update-user", value),
//   getById: (value) => AxiosInterceptor().post("/get-user", value),
//   getAll: (value) => AxiosInterceptor().post("/get-all-user", value),
//   add: (value) => AxiosInterceptor().post("/add-user", value),
//   delete: (value) => AxiosInterceptor().post("delete-questionnaire", value),
// };

// export default TeacherManagementService;


import AxiosInterceptor from "../utils/AxiosInterceptor";

const TeacherManagementService = {
    getAll: (value) => AxiosInterceptor().post("/get-all-user", value),
    edit: (value) => AxiosInterceptor().post("/update-user", value),
    getById: (value) => AxiosInterceptor().post("/get-user", value),
    add: (value) => AxiosInterceptor().post("/add-user", value),
    delete: (value) => AxiosInterceptor().post("/delete-user", value),
    taskget: (value) => AxiosInterceptor().post("/get-all-questionnaire-tasks", value),
    // getDropdownLanguages: (value) =>
    // AxiosInterceptor().post("/get-all-course-entity-without-pagination", value),
};

export default TeacherManagementService;